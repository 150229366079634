import { Client } from '@nrdy-marketing-engine/cart/@types/cart-state';
import { Me } from '@nrdy-marketing-engine/cart/@types/me';
import { StudentDataResponse } from '@nrdy-marketing-engine/cart/@types/student-data';
import getContracts from '@nrdy-marketing-engine/cart/src/api/user/contracts/get-contracts';
import { transformMeToClient } from '@nrdy-marketing-engine/cart/src/api/user/create-account';
import createStudent from '@nrdy-marketing-engine/cart/src/api/user/create-student';
import getStudents from '@nrdy-marketing-engine/cart/src/api/user/fetch-student';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { AvailableUserCreationTypes } from '../@types/available-user-creation-types';
import type { UserContextInterface } from '../context';
import { UserController } from '../user-controller';

const userController = new UserController();

function useUser(defaults?: UserContextInterface): UserContextInterface {
  const [user, setUser] = useState(defaults?.user);
  const [loading, setLoading] = useState(true);
  const [refetchUser, setRefetchUser] = useState(false);
  const [clientID, setClientID] = useState(defaults?.clientID);
  const [contracts, setContracts] = useState([]);
  const ldClient = useLDClient();

  const [students, setStudents] = useState<StudentDataResponse>(null);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [studentsError, setStudentsError] = useState(null);

  const convertUserAndStore = useCallback(() => {
    return userController
      .usersMeConvert()
      .then((data) => {
        setLoading(true);
        setRefetchUser(true);

        return data;
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
        throw err;
      });
  }, []);

  const createUserAndStore = useCallback(
    async (client: Client, type?: AvailableUserCreationTypes) => {
      try {
        const { userID } = await userController.createAccount(client, undefined, type);
        await ldClient.identify({ kind: 'user', key: userID });

        setLoading(true);
        const fetchedUser = await fetchUserAndStore();
        // If the user comes from a quote he already has a student after creation
        if (fetchedUser?.entity?.client_uuid && fetchedUser?.students?.length === 0) {
          const student = await createStudent(transformMeToClient(fetchedUser));
          setStudents([student]);
          return student;
        }
        return fetchedUser;
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [ldClient]
  );

  const fetchUserAndStore = useCallback(() => {
    return userController
      .usersMeGet()
      .then((user) => {
        updateStateUser(user);
        return user;
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  const refetchUserAndStore = useCallback(() => fetchUserAndStore(), [fetchUserAndStore]);

  const signInAndStore = useCallback(({ email, password }: Partial<Client>) => {
    return userController
      .signIn({ email, password })
      .then((data) => {
        setLoading(true);
        setRefetchUser(true);

        return data;
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
        throw err;
      });
  }, []);

  const updateStateUser = async (user: Me) => {
    if (user?.entity) {
      const clientId = user.entity.client_uuid;
      setUser(user);
      setClientID(clientId);
      if (
        !window.sessionStorage.getItem(`emailError-${window.location.search}`) &&
        ['tutor', 'student'].includes(user.entity?.role) &&
        window.location.href.includes('/membership/checkout')
      ) {
        window.sessionStorage.setItem(`emailError-${window.location.search}`, 'true');
        window.sessionStorage.setItem(`email-${window.location.search}`, user?.entity?.email);
        window.location.href = `/nme/logout?redirect_to=${window.location.href}`;
      }
      if (clientId) setContracts(await getContracts({ uuid: clientId }));
    }
  };

  useEffect(
    function _refetchUser() {
      if (refetchUser) {
        setRefetchUser(false);
        fetchUserAndStore();
      }
    },
    [refetchUser, fetchUserAndStore]
  );

  useEffect(
    function _initialUserSetup() {
      if (!user && loading) fetchUserAndStore();
    },
    [user, loading, fetchUserAndStore]
  );

  useEffect(() => {
    if (!window?.location?.href?.includes('/membership/checkout')) return;
    if (students?.length) return;
    if (loading) return;
    if (!user?.entity?.client_uuid) return;
    if (user?.students?.length === 0) return;
    setLoadingStudent(true);
    getStudents(user.entity.client_uuid)
      .then((students) => setStudents(students))
      .catch((err) => {
        setStudentsError(err);
        window['newrelic'] && window['newrelic'].noticeError(err);
      })
      .finally(() => {
        setLoadingStudent(false);
      });
  }, [user, loading]);

  return {
    clientID,
    contracts,
    convertUserAndStore,
    createUserAndStore,
    loading: loading || loadingStudent,
    refetchUserAndStore,
    signInAndStore,
    students,
    studentsError,
    user,
  };
}

export default useUser;
